import {Tab} from "../../components/Tab";

export const tab = {
    render: Tab,
    children: ["paragraph", "list", "tag"],
    attributes: {
        title: {
            type: String,
            required: true,
        },
    },
};