// Option.tsx
import React from 'react';

export const Option = ({ title, children }) => {
    return (
        <div className="option">
            <h3>{title}</h3>
            <div className="code">{children}</div>
            <style jsx>{`
        .option {
          display: none;
        }
        .option.active {
          display: block;
        }
        .code {
          background-color: lightgray;
          padding: 10px;
          border-radius: 4px;
        }
      `}</style>
        </div>
    );
};
