import {Callout} from '../../components';

export const div = {
  render: "div",
  children: ['paragraph', 'tag', 'list'],
  attributes: {
    class: {
      type: String
    },
    cols: {
      type: Number,
    }
  },
};
