import {Callout} from '../../components';
import Embed from "../../components/Embed";

export const embed = {
  render: Embed,
  children: ['paragraph', 'tag', 'list'],
  attributes: {
    src: {
      type: String
    },
    width: {
      type: String
    },
    height: {
      type: String
    },
    allow: {
      type: String
    },
    allowfullscreen: {
      type: String
    }
  },
};
