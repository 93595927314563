import React, { useState, ReactNode } from "react";

interface AccordionComponentProps {
    children: ReactNode;
    title?: string; // Make title optional
}

export const AccordionComponent: React.FC<AccordionComponentProps> = ({ children, title = '' }) => {
    const [activeAccordion, setActiveAccordion] = useState(-1);

    const handleAccordionClick = (index: number) => {
        setActiveAccordion(activeAccordion === index ? -1 : index);
    };

    const parseTitle = (title: string) => {
        const parts = title.split(/(`.*?`)/).map((part, index) => {
            if (part.startsWith("`") && part.endsWith("`")) {
                return <code key={index}>{part.slice(1, -1)}</code>;
            }
            return part;
        });
        return parts;
    };

    return (
        <div className={`accordion ${activeAccordion === 0 ? 'active' : ''}`}>
            <div className="title" onClick={() => handleAccordionClick(0)}>
                <p>{parseTitle(title)}</p>
            </div>
            <div className={`accordion-content ${activeAccordion === 0 ? 'active' : ''}`}>
                {children}
            </div>
        </div>
    );
};
