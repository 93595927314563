import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas, far, fab);

interface IconProps {
    icon?: any;
    iconType?: string;
    color?: string;
    className?: string;
}

const Icon: React.FC<IconProps> = ({ icon, iconType, color, className }) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    if (!isClient) {
        return null;
    }

    let iconPrefix;
    switch (iconType) {
        case 'solid':
            iconPrefix = 'fas';
            break;
        case 'regular':
            iconPrefix = 'far';
            break;
        case 'brands':
            iconPrefix = 'fab';
            break;
        default:
            iconPrefix = 'fas';
    }

    return (
        <div className={"fontawesome-icon"}>
            <FontAwesomeIcon icon={[iconPrefix, icon]} color={color} className={className} />
        </div>
    );
};

export default Icon;
