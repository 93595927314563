// tags/option.markdoc.ts
import { nodes } from '@markdoc/markdoc';
import {Option} from "../../components/Code-dropdown/Option";

export const option = {
    render: Option,
    attributes: {
        title: {
            type: String,
            required: true,
        },
        filename: {
            type: String,
            required: false,
        },
    },
};
