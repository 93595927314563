import Icon from "../../components/FontAwesomeMw/icon";

export const icon = {
    render: Icon,
    attributes: {
        icon: { type: String, required: true },
        iconType: { type: String, default: 'solid' },
        color: { type: String, default: '#000' },
        class: {type: String}
    },
};
