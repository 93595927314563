import ProjectListWrapper from "../../components/project-wrapper";

export const getproject = {
  render: ProjectListWrapper,
  children: ['paragraph', 'tag', 'list', 'code'],
  attributes: {
    class: { type: String },
    initialToken: { type: String }
  }
};
