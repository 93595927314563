import React, { useEffect, useRef, useState } from "react";

const GetProjectList = ({ onSelect, projectName }) => {
    const [projects, setProjects] = useState([]);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const token = localStorage.getItem("token");
        const redirectDomain = localStorage.getItem("redirectDomain");

        const fetchData = async () => {
            if (token) {
                try {
                    const response = await fetch(`https://${redirectDomain}.middleware.io/api/v1/docs/projects`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (!response.ok) {
                        throw new Error("Failed to fetch data");
                    }
                    const data = await response.json();
                    setProjects(data.items);
                    if (data.items.length > 0) {
                        if (selectedProject == null) {
                            setSelectedProject(data.items[0]);
                            onSelect(data.items[0].token);
                            projectName(data.items[0].uid);
                        }
                    }
                } catch (error) {
                    setError(error.message);
                }
            } else {
                setError("Token not found");
            }
        };

        fetchData();
    }, [onSelect, projectName]);

    const handleSelect = (project) => {
        setSelectedProject(project);
        onSelect(project.token);
        setIsOpen(false);
        projectName(project.uid);
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            {/*{error && <p>{error}</p>}*/}
            <div className="custom-dropdown" ref={dropdownRef}>
                <button
                    className={`dropdown-toggle ${isOpen ? "active" : ""}`}
                    onClick={handleToggle}
                    title="To access your API Keys, login to your Middleware Account in the top right of your screen."
                >
                    {selectedProject ? `${selectedProject.name} : ${selectedProject.token}` : 'Your API Keys'}
                </button>

                {isOpen && (
                    <div className="dropdown-menu">
                        {projects.map((project, index) => (
                            <div
                                key={index}
                                className="dropdown-item"
                                onClick={() => handleSelect(project)}
                            >
                                <strong>{project.name}</strong> : {project.token}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default GetProjectList;
