
export const callout = {
  render: "callout",
  children: ['paragraph', 'tag', 'list'],
  attributes: {
    title: {
      type: String,
      default: "note",
      matches: ["check", "error", "note", "warning"]
    },
    type: {
      default: "info",
      matches: ["info", "warning", "note"]
    }
  },
};
