import React, {useState} from "react";

export const Tabs = ({ children }) => {
    const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <div className="tabs">
            <div className="tab-titles">
                {React.Children.map(children, (child, index) => {
                    return (
                        <div
                            key={index}
                            className={`tab-title ${activeTab === index ? 'active' : ''}`}
                            onClick={() => handleTabClick(index)}
                        >
                            {child.props.title}
                        </div>
                    );
                })}
            </div>
            <div className="tab-contents">
                {React.Children.map(children, (child, index) => {
                    return React.cloneElement(child, {
                        isActive: activeTab === index,
                    });
                })}
            </div>
        </div>
    );
};