import React from 'react';

const Embed = ({ src, width = '100%', height = '100%', frameborder = '0', allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture', allowfullscreen = true }) => {
    return (
        <div className={"embed-video"}>
            <iframe
                src={src}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                frameBorder={frameborder}
                allow={allow}
                allowFullScreen={allowfullscreen}
            ></iframe>
        </div>
    );
};

export default Embed;
