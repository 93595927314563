import { AccordionComponent } from "../../components/AccordionComponent";

export const accordion = {
    render: AccordionComponent,
    children: ["paragraph", "list", "tag"],
    attributes: {
        title: {
            type: String,
            required: true,
        },
    },
};
