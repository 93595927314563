import React, { useState, ReactNode, useEffect, useRef } from 'react';
import Image from "next/image";

interface DropdownProps {
    children: ReactNode;
}

interface ChildProps {
    title: string;
    filename: string;
    children: ReactNode;
}

export const Dropdown: React.FC<DropdownProps> = ({ children }) => {
    const childArray = React.Children.toArray(children) as React.ReactElement<ChildProps>[];
    const [selectedOption, setSelectedOption] = useState(childArray[0]?.props.title || '');
    const [fileName, setFileName] = useState(childArray[0]?.props.filename || '');
    const [showOptions, setShowOptions] = useState(false);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleOptionChange = (title: string, filename: string) => {
        setSelectedOption(title);
        setShowOptions(false);
        setFileName(filename);
    };

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowOptions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="dropdown" ref={dropdownRef}>
            <div className={'d-flex'}>
                <p className={'language-name-title'}>{fileName}</p>
                <div className="dropdown-button" onClick={toggleOptions}>
                    {selectedOption}
                    <span className="arrow"><Image src={'/images/icon/dropdown.svg'} width={10} height={20} alt="" /></span>
                </div>
                {showOptions && (
                    <div className="options">
                        {childArray.map((child) => (
                            <div
                                key={child.props.title}
                                className={`option ${selectedOption === child.props.title ? 'active' : ''}`}
                                onClick={() => handleOptionChange(child.props.title, child.props.filename)}
                            >
                                {child.props.title}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="code-container">
                {childArray.find((child) => child.props.title === selectedOption)?.props.children}
            </div>
        </div>
    );
};
