import React from "react";
import Image from "next/image";
import Link from "next/link";

export const WidgetMarkdoc = ({ title, icon, hyperlink, subTitle, points}) => {
    return (<>
        <div className={'overview-widget'}>
            <a href={hyperlink}>
                <div className={'card'}>
                    {icon ? <Image src={icon} alt={title} width={100} height={100}/> : <p className={'number-title'}>{points}</p>}
                    <h4>{title}</h4>
                    <p>{subTitle}</p>
                </div>
            </a>
        </div>
        </>
)
    ;
};