import React, { useState } from 'react';

const ImageComponent = ({ src, alt, title, width, height }) => {
    const [isZoomed, setIsZoomed] = useState(false);

    const handleImageClick = () => {
        setIsZoomed(!isZoomed);
    };

    const handleOutsideClick = (event) => {
        if (event.target.className === 'zoomed-image-container') {
            setIsZoomed(false);
        }
    };

    return (
        <div>
            <img
                src={src}
                alt={alt}
                title={title}
                style={{
                    width: width,
                    height: height,
                    cursor: 'pointer',
                    transition: 'transform 0.3s ease',
                    marginBottom: '20px'
                }}
                onClick={handleImageClick}
            />
            {isZoomed && (
                <div
                    className="zoomed-image-container"
                    onClick={handleOutsideClick}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                    }}
                >
                    <div style={{ position: 'relative', maxWidth: '90%' }}>
                        <img
                            src={src}
                            alt={alt}
                            title={title}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '90%',
                                transition: 'transform 0.3s ease',
                                cursor: 'zoom-out',
                                width: "100%",
                                margin: "auto"
                            }}
                            onClick={handleImageClick}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageComponent;
