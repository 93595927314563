import {Callout} from '../../components';
import ImageComponent from "../../components/Image";

export const image = {
  render: ImageComponent,
  children: ['paragraph', 'tag', 'list'],
  attributes: {
    src: { type: String, required: true },
    alt: { type: String },
    title: { type: String },
    width: { type: String },
    height: { type: String },
  },
};
