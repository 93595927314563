import React, { useState, ReactNode, isValidElement, cloneElement, ReactElement } from "react";
import GetProjectList from "./project_list";

interface ProjectListWrapperProps {
    initialToken: string;
    children: React.ReactNode;
    selectedToken: string;
    selectedUid: string;
}

const ProjectListWrapper: React.FC<ProjectListWrapperProps> = ({ children }) => {
    const [selectedToken, setSelectedToken] = useState("<MW_API_KEY>");
    const [selectedUid, setSelectedUid] = useState("<MW_UID>");

    const handleSelect = (token: string) => {
        setSelectedToken(token);
    };

    const handleSelectUid = (uid: string) => {
        setSelectedUid(uid);
    };

    const updatedChildren = React.Children.map(children, (child) => {
        if (isValidElement(child) && typeof child.props.children === 'string') {
            return cloneElement(child as ReactElement<{ children: string }>, {
                children: (child.props.children as string)
                    .replace(/your-initial-token/g, `${selectedToken}`)
                    .replace(/your-initial-uid/g, `https://${selectedUid}.middleware.io:443`),
            });
        } else if (typeof child === 'string') {
            return child
                .replace(/your-initial-token/g, `${selectedToken}`)
                .replace(/your-initial-uid/g, `https://${selectedUid}.middleware.io:443`);
        }
        return child;
    });

    return (
        <div className="project-code">
            <div className="token-project">
                <GetProjectList onSelect={handleSelect} projectName={handleSelectUid} />
            </div>
            {updatedChildren}
        </div>
    );
};

export default ProjectListWrapper;
