import {WidgetMarkdoc} from "../../components/Widget";

export const widgetMarkdoc = {
    render: WidgetMarkdoc,
    children: ["paragraph", "list", "tag"],
    attributes: {
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
        points: {
            type: String,
            required: false,
        },
        hyperlink: {
            type: String,
            required: true,
        },
        subTitle: {
            type: String,
            required: false,
        },
    },
};