import {Callout} from '../../components';

export const span = {
  render: "span",
  children: ['paragraph', 'tag', 'list'],
  attributes: {
    class: {
      type: String
    }
  },
};
